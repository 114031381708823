import 'jquery-validation';

;(function($) {

	$.validator.addMethod(
    "SpaceOnlyNg",
    function (val, elem) {
      return this.optional(elem) || val.trim().length > 0;
    },
    "必須入力です。" // デフォルトメッセージ
	);

	$(document).ready(function() {
		$('#form').validate({
			rules: {
				company_name: {
					required: true,
					SpaceOnlyNg: true
				},
				last_name: {
					required: true,
					SpaceOnlyNg: true
				},
				first_name: {
					required: true,
					SpaceOnlyNg: true
				},
				email: {
					required: true,
					email: true,
					SpaceOnlyNg: true
				},
				tel: {
					required: true,
					SpaceOnlyNg: true
				},
				pref: {
					required: true,
					SpaceOnlyNg: true
				},
				industry: {
					required: true,
					SpaceOnlyNg: true
				},
				agree: {
					required: true
				}
			},
			messages: {
				company_name: {
					required: '貴社名を入力してください。',
				},
				last_name: {
					required: 'お名前を入力してください。',
				},
				first_name: {
					required: 'お名前を入力してください。',
				},
				email: {
					required: 'メールアドレスを入力してください。',
					email: 'メールアドレスの形式が正しくありません。',
				},
				tel: {
					required: '電話番号を入力してください。',
				},
				pref: {
					required: '都道府県を選択してください。',
				},
				industry: {
					required: '業種を選択してください。',
				},
				agree: {
					required: 'このチェックボックスをクリックしてください。',
				}
			},
			//エラーメッセージ位置変更
      errorPlacement: function(error, element){
        //個人情報の取り扱いのみエラー表示位置変更
        if(element.attr('name') === 'agree'){
          error.insertAfter('#agree_error');
        //標準出力箇所（フォーム項目の後にエラーメッセージを出力）
        }else{    
          error.insertAfter(element);
        }
      },
			//空欄の時もエラーメッセージを出す
			onfocusout: function(el) {
				$(el).valid();
			},
		});	

	});

}(jQuery));
