import Swiper from 'swiper/dist/js/swiper.js';

;(function($) {
  $(function(){

    $('#loopslider').each(function(){
        var loopsliderWidth = $(this).width();
        var loopsliderHeight = $(this).height();
        $(this).children('ul').wrapAll('<div id="loopslider_wrap"></div>');
 
        var listWidth = $('#loopslider_wrap').children('ul').children('li').width();
        var listCount = $('#loopslider_wrap').children('ul').children('li').length;
 
        var loopWidth = (listWidth)*(listCount);
 
        $('#loopslider_wrap').css({
            top: '0',
            left: '0',
            width: ((loopWidth) * 2),
            height: (loopsliderHeight),
            overflow: 'hidden',
            position: 'absolute'
        });
 
        $('#loopslider_wrap ul').css({
            width: (loopWidth)
        });
        loopsliderPosition();
 
        function loopsliderPosition(){
            $('#loopslider_wrap').css({left:'0'});
            $('#loopslider_wrap').stop().animate({left:'-' + (loopWidth) + 'px'},25000,'linear');
            setTimeout(function(){
                loopsliderPosition();
            },25000);
        };
 
        $('#loopslider_wrap ul').clone().appendTo('#loopslider_wrap');
    });
});
  
  
  $(function(){
    $(window).scroll(function (){
      $('.fadeup, .fadein').each(function(){
        var elemPos = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight + 200){
          $(this).addClass('scrollin');
        }
      });
    });
  });
  
  
  $(document).ready(function() {
       $(".function_content").hide();
       $("ul.tabs li:first").addClass("active").show();
       $(".function_content:first").show();
       $("ul.tabs li").click(function() {
            $("ul.tabs li").removeClass("active");
            $(this).addClass("active");
            $(".function_content").hide();
            var activeTab = $(this).find("a").attr("href");
            $(activeTab).fadeIn();
            return false;
       });
  });
  
  $(function(){
    $('a[href^="#"]').click(function(){
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });
  });
  
  $(function(){
    var setImg = '#mv-fade';
    var fadeSpeed = 1000;
    var switchDelay = 5000;
  
    $(setImg).children('img').css({opacity:'0'});
    $(setImg + ' img:first').stop().animate({opacity:'1',zIndex:'-1'},fadeSpeed);
  
    setInterval(function(){
        $(setImg + ' :first-child').animate({opacity:'0',zIndex:'-2'},fadeSpeed).next('img').animate({opacity:'1',zIndex:'-1'},fadeSpeed).end().appendTo(setImg);
    },switchDelay);
  });
 
  $(function(){
    var setImg = '#mv-sp-fade';
    var fadeSpeed = 1000;
    var switchDelay = 5000;
  
    $(setImg).children('img').css({opacity:'0'});
    $(setImg + ' img:first').stop().animate({opacity:'1',zIndex:'-1'},fadeSpeed);
  
    setInterval(function(){
        $(setImg + ' :first-child').animate({opacity:'0',zIndex:'-2'},fadeSpeed).next('img').animate({opacity:'1',zIndex:'-1'},fadeSpeed).end().appendTo(setImg);
    },switchDelay);
  });
 
  $(function(){
    $(".accordion li a").on("click", function() {
      $(this).next().slideToggle(); 
      // activeが存在する場合
      if ($(this).children(".accordion_icon").hasClass('active')) {     
        // activeを削除
        $(this).children(".accordion_icon").removeClass('active');        
      }
      else {
        // activeを追加
        $(this).children(".accordion_icon").addClass('active');     
      }     
    });
  });
  
  $(function() {
    $('.campain-banner > button').click(function(){
        $('.campain-banner').hide();
    });
  
    $('.c-navbar .luxbar-hamburger').on('click', function () {
        $('.c-navbar').toggleClass('open');
    });
  
  });
  

  // /* page link */
  $('.client').click(function() {
    var adjust = 120;
    var speed = 400;
    var position = $("#client").offset().top - adjust;
    $("html, body").animate({scrollTop: position}, speed);
  });

  $('[data-toggle="tooltip"]').tooltip()

  /* header change */
  window.addEventListener( "scroll", function() {

    var headerElement = document.getElementById( "header" );
    if (!headerElement) return
    var rect = headerElement.getBoundingClientRect();
    var y = rect.top + window.pageYOffset;
    if (y > 100) {
      headerElement.classList.remove('hidden');
    } else {
      headerElement.classList.add('hidden');
    }
  } ) ;

  //企業TOP用swiper
  var swiper = new Swiper('.l-branch-top-case__block .swiper-container', {
    loop: true,
    slidesPerView: 1.3,
    spaceBetween: 64,
    centeredSlides : true,
    speed: 1000,
    autoplay:{
      delay: 3000,
      disableOnInteraction: false, //ユーザーがスワイプなどの操作しても止まらない
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides : true,
      }
    },
  });

  //アンバサダー用swiper
  var swiper = new Swiper('.l-ambassador-content__episode-box .swiper-container', {
    loop: true,
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 40,
    centeredSlides : true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        }
      },
  });

  //タレントTOP用swiper
  var swiper = new Swiper('.p-talent .l-case .swiper-container', {
    loop: true,
    speed: 1000,
    slidesPerView: 1.2,
    spaceBetween: 40,
    centeredSlides : true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 1.1,
        spaceBetween: 20,
        }
      },
  });

  $(function(){
    $(".accordion_header").click(function(){
      $(".change-image img").attr("src",$(this).attr("href"));
      return false;
    });
  });

  $(function(){
    //.accordion_oneの中の.accordion_headerがクリックされたら
    $('.accordion .accordion_one .accordion_header').click(function(){
      //クリックされた.accordion_oneの中の.accordion_headerに隣接する.accordion_innerが開いたり閉じたりする。
      $(this).next('.accordion_inner').slideToggle();
      $(this).toggleClass("open");
      //クリックされた.accordion_oneの中の.accordion_header以外の.accordion_oneの中の.accordion_headerに隣接する.accordion_oneの中の.accordion_innerを閉じる
      $('.accordion .accordion_one .accordion_header').not($(this)).next('.accordion_one .accordion_inner').slideUp();
      $('.accordion .accordion_one .accordion_header').not($(this)).removeClass("open");
      $('.accordion .accordion_one .accordion_header.stay').not($(this)).toggleClass("open");
    });
  });

  $(function() {
    $('.popup-btn').on('click', function() {
  //     ボタンをクリックしたら、クリックしたい要素のdata属性を取得
      let modalClassName = $(this).data('popup-link');
  //     上記で取得した要素と同じclass名を持つ要素を取得
      $("."+modalClassName).toggleClass('is-show');
    });
    
  //   modalを閉じるときの動作
    $('.popup__bg, #js-close-btn').on('click', function() {
     $(this).parents('.popup').toggleClass('is-show');
    });
  });

  $(function() {
    let tabs = $(".tab"); // tabのクラスを全て取得し、変数tabsに配列で定義
    $(".tab").on("click", function() { // tabをクリックしたらイベント発火
      $(".active").removeClass("active"); // activeクラスを消す
      $(this).addClass("active"); // クリックした箇所にactiveクラスを追加
      const index = tabs.index(this); // クリックした箇所がタブの何番目か判定し、定数indexとして定義
      $(".content").removeClass("show").eq(index).addClass("show"); // showクラスを消して、contentクラスのindex番目にshowクラスを追加
    })
  })

  $("#dropdown .dropmenu-item-title").on("click", function() {
    $(this).next().slideToggle();
    $(this).next().toggleClass('is-show');
    $('.dropmenu').toggleClass('is-show');
  });

  $('#talent-search-area-select-box').bind('change', function() {
    $(this).parents('form').submit()
  });

  $(function(){
    var setImg = '#mv-slide';
    var fadeSpeed = 1200;
    var switchDelay = 5000;

    $(setImg).children('img').css({opacity:'0'});
    $(setImg + ' img:first').stop().animate({opacity:'1',zIndex:'20'},fadeSpeed);

    setInterval(function(){
        $(setImg + ' :first-child').animate({opacity:'0'},fadeSpeed).next('img').animate({opacity:'1'},fadeSpeed).end().appendTo(setImg);
    },switchDelay);
  });

  jQuery(function($){
    $('.slider__inner').each(function(){
      var sliderWidth = $(this).width();
      $(this).clone(true).insertBefore(this);
      $(this).clone(true).insertAfter(this);

      $('.slider').css('width', sliderWidth*3);
    });
    $('.reverse__inner').each(function(){
      var sliderWidth = $(this).width();
      $(this).clone(true).insertBefore(this);
      $(this).clone(true).insertAfter(this);

      $('.reverse').css('width', sliderWidth*3);
    });
  });

  $(function() {
    $('#service-doc-postal').on('change', async function() {
      let postalCode = $(this).val();
      if (postalCode.length === 7) {
        let postal = await fetchAddress(postalCode);
        if (postal) {
          $('#service-doc-pref').val(postal.pref);
          if ($('#service-doc-pref-id')) {
            $('#service-doc-pref-id').val(postal.pref_id);
          }
          $('#service-doc-city').val(postal.city);
          $('#service-doc-address').val(postal.address);
        }
      }
    });

    const fetchAddress = (code) => {
      return $.ajax({
        type: 'GET',
        url: `/api/postals`,
        data: {
          code: code
        }
      }).then(res => {
        return res;
      }).catch(error => {
        return null;
      });
    }

  });
  //業界別LP、代理店 LPメインビジュアルにCTAボタンがかからないようにする 
  if (window.matchMedia( "(max-width: 768px)" ).matches) {
    jQuery(window).on('scroll', function ($) {
      if (100 < jQuery(this).scrollTop()) {
        jQuery('.fixed-button').show();
      } else {
        jQuery('.fixed-button').hide();
      }
    });
  } else {
    jQuery('.fixed-button').hide();
  }
  
  //業界別LP、代理店LP 資料請求フォーム前でCTA追従消える
  $(document).ready(function () {
    $(window).on("scroll", function () {
      if (window.matchMedia('(max-width: 767px)').matches) {
        var scrollHeight = $(document).height(); //ドキュメントの高さ
        var scrollPosition = $(window).height() + $(window).scrollTop(); //現在の位置
        var footHeight = $("footer").innerHeight(); //フッターの高さ
        var ctaHeight = $(".cta").innerHeight(); //フッターの高さ
        if (scrollHeight - scrollPosition <= footHeight + ctaHeight ) {
          $('.fixed-button').addClass('is-hidden');
        } else {
          $('.fixed-button').removeClass('is-hidden');
        }
      }
    });
  });

  var rellax = new Rellax('.js-rellax', {
    speed: -2,
    center: true,
    round: true,
    vertical: true,
    horizontal: false
  });

  // ふわっと表示させるアニメーション
  function delayScrollAnime() {
    var time = 0.3;
    var value = time;
    $('.delayScroll').each(function () {
      var parent = this;
      var elemPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      var childs = $(this).children();
      
      if (scroll >= elemPos - windowHeight && !$(parent).hasClass("play")) {
        $(childs).each(function () {
          
          if (!$(this).hasClass("fadeUp")) {
            
            $(parent).addClass("play");
            $(this).css("animation-delay", value + "s");
            $(this).addClass("fadeUp");
            value = value + time;
            
            var index = $(childs).index(this);
            if((childs.length-1) == index){
              $(parent).removeClass("play");
            }
          }
        })
      }else {
        $(childs).removeClass("fadeUp");
        value = time;
      }
    })
  }

  $(window).scroll(function (){
    delayScrollAnime();/* アニメーション用の関数を呼ぶ*/
  });// ここまで画面をスクロールをしたら動かしたい場合の記述

  // 画面が読み込まれたらすぐに動かしたい場合の記述
  $(window).on('load', function(){
    delayScrollAnime();/* アニメーション用の関数を呼ぶ*/
  });// ここまで画面が読み込まれたらすぐに動かしたい場合の記述

  //広告LPのcta表示・非表示
  jQuery(window).scroll(function() {
    var scrollTop = $(window).scrollTop();
    var dispHeight = $(window).height();
    if ( $('.l-landing-price').length ) {
      var areaTop = $('.l-landing-price').offset().top; 
      
      if (scrollTop > areaTop-dispHeight) {
        $(".l-landing-fixed").addClass('is-visible'); 
      } else {
        $(".l-landing-fixed").removeClass('is-visible'); 
      }
    }
  });

  jQuery(function() {

    var lpForms = $('.l-landing-form').innerHeight();
    var lpPhone= $('.l-landing-phone').innerHeight();
    var footer = $('.l-footer').innerHeight();
    
    window.onscroll = function () {
      var scrollHeight = $(document).height(); //ドキュメントの高さ
      var scrollPosition = $(window).height() + $(window).scrollTop(); //現在の位置
    
      if(scrollHeight - scrollPosition <= footer + lpPhone + lpForms ){
        $('.l-landing-fixed').addClass('is-hidden');
      }else{
        $('.l-landing-fixed').removeClass('is-hidden');
      }
    };
  });

  //アンバサダーLP 画面スクロールとメニューを連動させる
  $(function () {
    $(window).on("load scroll resize", function () {

      var st = $(window).scrollTop();
      var wh = $(window).height();

      $('.scroll-point').each(function (i) {
        var tg = $(this).offset().top;
        var id = $(this).attr('id');

        if (st > tg  - wh + (wh / 2)) {
          $(".header__link").removeClass("is-active");
          var link = $(".header__link[href *= " + id +"]");
          $(link).addClass("is-active");
        }
      });
    });
  });

  // アンバサダーLP フローティングボタン
  $(window).scroll(function () {
    if (window.matchMedia('(max-width: 767px)').matches) {
      if($(window).scrollTop() > 600) {
        $('.ambassador-floating-btn').addClass('fixed');
      } else {
        $('.ambassador-floating-btn').removeClass('fixed');
      }
    }
  });
  
  //アンバサダーLP よくある質問の手前で消える
  $(document).ready(function () {
    $(window).on("scroll", function () {
      if (window.matchMedia('(max-width: 767px)').matches) {
        var scrollHeight = $(document).height(); //ドキュメントの高さ
        var scrollPosition = $(window).height() + $(window).scrollTop(); //現在の位置
        var footHeight = $("footer").innerHeight(); //フッターの高さ
        var faqHeight = $(".l-ambassador-content__faq").innerHeight(); //フッターの高さ
        if (scrollHeight - scrollPosition <= footHeight + faqHeight ) {
          $('.ambassador-button').fadeOut(200)
        } else {
          $('.ambassador-button').fadeIn(200);
        }
      }
    });
  });

})(jQuery);


