$(function() {
  /***************
      タブ
  ****************/
  $(".c-tab li").click(function() { //タブをクリックしたときのファンクションをまとめて指定
      var num = $(".c-tab li").index(this); //.index()を使いクリックされたタブが何番目かを調べ、numという変数に代入
    
      $(".c-tab-content").removeClass('active'); //コンテンツを一度すべて非表示
    
      $(".c-tab-content").eq(num).addClass('active'); //クリックされたタブと同じ順番のコンテンツを.activeをクラスに追加して表示
    
      $(".c-tab li").removeClass('active');　//一度タブについているクラス.activeを消して
    
      $(this).addClass('active')　//クリックされたタブのみにクラス.activeをつける
    
  });

  $('#pills-tab a').on('click', function (e) {
    console.log(e)
    e.preventDefault()
    $(this).tab('show')
  })

});